'use client';

import { setBuilderUserAttributes } from 'builder/clientUtils';
import { isOrganicReferrer } from 'core-events';
import { incrementTelemetry } from 'core-events';
import { startTransition, useEffect } from 'react';

import { detectBot } from './lib/botDetection';

/**
 * These effects are intended to run once per visit to our website.
 * Since the Layout will persist across page navigations, VisitorSetup should be included in the root "layout.tsx" of the app folder.
 */
export default function VisitorSetup() {
    useEffect(() => {
        startTransition(() => {
            detectBot().then((result) => {
                const payload = {
                    url: window.location.href,
                    isBot: result.bot,
                };

                void fetch('/api/visitor-setup', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (result.bot) {
                    void incrementTelemetry('visitor.botVisitorIdDenied');
                }
            });
        });

        // determine if traffic is organic for Builder.io Segmentation
        if (window !== undefined && window.location !== undefined && window.location.href !== undefined) {
            const searchParams = new URL(window.location.href).searchParams ?? new URLSearchParams();
            setBuilderUserAttributes({ organic: isOrganicReferrer(searchParams) });
        }
    }, []);
    return <></>;
}
