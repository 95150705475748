'use client';

import React from 'react';

interface LinkObject {
    id?: string | null;
    title: string;
    url: string;
}

interface LinkGroup {
    title: string;
    groupClass?: string;
    links: LinkObject[];
}

interface SiteFooterLinkProps {
    linkGroup: LinkGroup;
}

const SiteFooterLink: React.FC<SiteFooterLinkProps> = ({ linkGroup }) => (
    <div className={linkGroup.groupClass}>
        <div className="pb-2">
            <b className="" role="heading" aria-level={3}>
                {linkGroup.title}
            </b>
        </div>
        <ul className="text-sm space-y-3">
            {linkGroup.links.map((link, index) => (
                <li key={index}>
                    <a href={link.url}>{link.title}</a>
                </li>
            ))}
        </ul>
    </div>
);

export default SiteFooterLink;
