'use client';

import { load, BotDetectionResult } from '@fingerprintjs/botd';
import { logger } from 'logging';

export async function detectBot(): Promise<BotDetectionResult> {
    try {
        const botd = await load();
        const result = await botd.detect();
        return result;
    } catch (error) {
        logger.error('Bot detection failed', error as Record<string, any>);
        throw error;
    }
}
