import { transports } from 'winston';

export type LoggerConfig = {
    logLevel: string;
    logFormat: string;
    datadog?: transports.HttpTransportOptions;
};

export function getLoggerConfig(): LoggerConfig {
    const {
        LOG_LEVEL = 'info',
        LOG_FORMAT = 'text',
        DD_RUM_APP_ID = '',
        DD_RUM_TOKEN = '',
    } = process.env;

    const config: LoggerConfig = {
        logLevel: LOG_LEVEL,
        logFormat: LOG_FORMAT,
        datadog: {},
    };

    if (!DD_RUM_APP_ID || !DD_RUM_TOKEN) {
        delete config.datadog;
    }

    return config;
}
