import winston from 'winston';

import { Logger } from './Logger';

export class WinstonLogger implements Logger {
    constructor(private readonly winstonLogger: winston.Logger) {}

    error(message: string, metadata?: Record<string, any>) {
        if (metadata) this.winstonLogger.error(message, metadata);
        else this.winstonLogger.error(message);
    }

    warn(message: string, metadata?: Record<string, any>) {
        if (metadata) this.winstonLogger.warn(message, metadata);
        else this.winstonLogger.warn(message);
    }

    info(message: string, metadata?: Record<string, any>) {
        if (metadata) this.winstonLogger.info(message, metadata);
        else this.winstonLogger.info(message);
    }
}
